import React, { useState } from "react"
import { MenuItem, Menu, ListItem } from "@material-ui/core"
import styled from "styled-components"
import colors from "styles/colors"
import { observer } from "mobx-react"
import { format } from "date-fns"
import { useConfigurationStore, useLogsStore, useUiStore } from "hooks/stores"
import LogsStatus from "./logs_status/logs_status"
import { resetLogHistoryHighlights } from "../../../utils/helpers"

const Wrapper = styled.div`
  width: 268px;
  margin: 0 auto;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(55, 55, 55, 0.16);
  position: relative;
`

const LabelLogFrom = styled.div`
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 8px;
`

const LogMessage = styled.div`
  white-space: pre-line;
  color: ${colors.black};
  margin-bottom: 8px;
`

const LogTimestamp = styled(LogMessage)`
  opacity: 0.5;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const ControlButtons = styled.div`
  display: inline-block;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 10px;
`

const StyledMenu = styled(Menu)`
  && {
    min-width: 100px;
    border-radius: 4px;
    background: transparent;
    color: black;
  }
`

const StyledMenuItem = styled(MenuItem)`
  && {
    z-index: 1500;
  }
`

const ActionWrapper = styled.div`
  z-index: 1401;
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 25px;

  &:hover {
    cursor: pointer;
  }

  &:before {
    content: "…";
    transform: rotate(90deg);
    color: black;
    position: relative;
    font-size: 20px;
    left: calc(50% - 2px);
    top: 7px;
    display: inline-flex;
  }
`

const LogsTile = ({ type, createdAt, message, payload, index, status }) => {
  const { setHistoryDeviceConfiguration } = useConfigurationStore()
  const { setCurrentLogTitle, setCurrentLogDate } = useLogsStore()
  const { openConfigurationModal } = useUiStore()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleLoadLogsConfiguration = (payload, message, createdAt, objId) => {
    let data = payload?.navigateInappAction?.data || payload

    resetLogHistoryHighlights()
    document.getElementById(objId).style.backgroundColor = "rgba(0, 0, 0, 0.1)"

    setCurrentLogTitle(message)
    setCurrentLogDate(createdAt)
    setHistoryDeviceConfiguration(data)
    openConfigurationModal()
    handleClose()
  }

  const showLogsMenu =
    payload?.navigateInappAction?.data || !!payload?.hearingProfiles?.length

  const objId = `logs-tile-${index}`
  const itemId = `${objId}-item`

  return (
    <ListItem key={itemId} id={itemId} class="list-items">
      <Wrapper>
        <LabelLogFrom>{type}</LabelLogFrom>
        <LogMessage>{message}</LogMessage>
        {showLogsMenu && (
          <ControlButtons>
            <ActionWrapper id={objId} onClick={handleClick} />
            <StyledMenu
              id={objId}
              anchorEl={anchorEl}
              open={Boolean(anchorEl && anchorEl.id === objId)}
              onClose={handleClose}
              disablePortal
            >
              {showLogsMenu && (
                <StyledMenuItem
                  onClick={() =>
                    handleLoadLogsConfiguration(
                      payload,
                      message,
                      createdAt,
                      itemId
                    )
                  }
                >
                  See details
                </StyledMenuItem>
              )}
            </StyledMenu>
          </ControlButtons>
        )}
        <LogTimestamp>
          {format(new Date(createdAt), "EEEE, MMMM d, yyyy h:mm aaa")}
        </LogTimestamp>
        {!!status && <LogsStatus status={status} />}
      </Wrapper>
    </ListItem>
  )
}

export default observer(LogsTile)
